/* Created by vadim.maltsev 03.04.2017
 * jQuery Sticky Table Headers
 */
(function ($) {
    'use strict';
    $.fn.extend({
        freezeTableItems: function (options) {
            var scrollableArea = this.parent(),
                wrapper = scrollableArea.parent(),
                offset = this.offset(), // Get the coordinates of the table at the position of the page
                th = this.find('thead th'),
                stickyTh = th.first(),
                stickyTd = this.find('tbody tr td:first-child'),
                defaults = {
                    bgColor: '#eee',
                    zIndex: 99,
                    transition: 'all 0.25s', // You should change this value, for example, "all 1s"
                    delay: 250,
                    freezeFirstColumn: true,
                    freezeHeader: true
                };
            options = $.extend({}, defaults, options);
            if (th.length === 0) {
                if (!!window.console) {
                    console.log('Unable to write html structure');
                }
            } else {
                // Make header sticky
                if (options.freezeHeader) {
                    var thCssOptionsForSticky = {
                            'background-color': options.bgColor,
                            'transition': options.transition,
                            'z-index': options.zIndex,
                            'position': 'relative'
                        },
                        getNewTopValue = function () {
                            var top = Math.max(document.body.scrollTop, document.documentElement.scrollTop);

                            var isFocusNotLostToTop = top > offset.top,
                                newTopOffset = top - offset.top,
                                isFocusNotLostToBottom = newTopOffset < (parseInt(wrapper.css('height')) - parseInt(stickyTh.css('height')));
                            return isFocusNotLostToTop && isFocusNotLostToBottom ? newTopOffset : 0;
                        }

                    thCssOptionsForSticky.top = getNewTopValue();
                    th.css(thCssOptionsForSticky);

                    var timeoutInstance = null;
                    // Add a screen scrolling event listener
                    $(window).on('scroll', function () {
                        if(timeoutInstance === null) {
                            timeoutInstance = setTimeout(function () {
                                th.css('top', getNewTopValue());
                                timeoutInstance = null;
                            }, options.delay);
                        }
                    });
                }

                // Make first column sticky
                if (options.freezeFirstColumn) {
                    // We made wrapper as a starting point for indentation
                    wrapper.css({
                        'position': 'relative',
                        'display': 'inline-flex',
                        'width': '100%'
                    });

                    // We make "scrollable-area" - scrollable
                    scrollableArea.css({
                        'overflow-x': 'auto',
                        'overflow-y': 'visible',
                        'width': '100%'
                    });

                    // We make table adaptive, it is temp value and we need it only for first render
                    this.css('width', '100%');

                    // We check width of the first cell in the header
                    // shift the table on this width and
                    // change width with use information about margin
                    var thWidth = stickyTh.css('width'),
                        newTableWidth = parseInt(this.css('width')) - parseInt(thWidth);
                    this.css('margin-left', stickyTh.css('width'));
                    this.css('width', newTableWidth);

                    // We make the cell in the header sticky.
                    // This cell is missing the width because we use position absolute, so we set this forced.
                    var cellStylesTh = {
                            'width': thWidth,
                            'position': 'absolute',
                            'left': 0,
                            'z-index': parseInt(options.zIndex) + 2
                        },
                        cellStylesTd = $.extend({}, cellStylesTh, {
                            'background': 'white',
                            'z-index': parseInt(options.zIndex) + 1
                        });

                    stickyTh.css(cellStylesTh);
                    stickyTd.css(cellStylesTd);
                }
            }
        }
    });

})(jQuery);